import { RouteObject } from 'react-router-dom';

import { ActivateAccount } from 'features/activate-account/ActivateAccount';
import { ActivateAccountError } from 'features/activate-account/ActivateAccountError';
import { ActivateAccountSuccess } from 'features/activate-account/ActivateAccountSuccess';
import { CampaignPreview } from 'features/campaign/CampaignPreview';
import { Campaigns } from 'features/campaign/Campaigns';
import { CheckoutSuccess, ItemCheckout } from 'features/checkout';
import { PageNotFound } from 'features/error/PageNotFound';
import { PaymentDeclined } from 'features/error/PaymentDeclined';
import { Home } from 'features/home/Home';
import { HotDeals } from 'features/item/HotDeals';
import { ItemPreview } from 'features/item/ItemPreview';
import { MessagePreview } from 'features/message/MessagePreview';
import { Messenger } from 'features/messenger/Messenger';
import { MyActivityBoughtPage } from 'features/my-profile/my-activity/MyActivityBought';
import { MyActivityCampaignsPage } from 'features/my-profile/my-activity/MyActivityCampaignsPage';
import { MyActivityDonatedPage } from 'features/my-profile/my-activity/MyActivityDonatedPage';
import { MyActivityPage } from 'features/my-profile/my-activity/MyActivityPage';
import { MyActivitySellingPage } from 'features/my-profile/my-activity/MyActivitySellingPage';
import { MyActivityTrackedItemPage } from 'features/my-profile/my-activity/MyActivityTrackedItemPage';
import { MyProfile } from 'features/my-profile/MyProfile';
import { PaymentPage } from 'features/my-profile/payment/PaymentPage';
import { ShippingAddressPage } from 'features/my-profile/shippingAddress/ShippingAddressPage';
import { ResetPassword } from 'features/reset-password/ResetPassword';
import { ResetPasswordError } from 'features/reset-password/ResetPasswordError';
import { ResetPasswordSuccess } from 'features/reset-password/ResetPasswordSuccess';
import { Download } from 'features/user/Download';
import { GivePhone } from 'features/user/GivePhone';
import { Thanks } from 'features/user/Thanks';
import { UnsubscribeEmail } from 'features/user/UnsubscribeEmail';
import { MyProfileWrapper } from 'navigation/wrapper/layout/MyProfileWrapper';
import { RootWrapper } from 'navigation/wrapper/RootWrapper';
import { DefaultScreenWrapper } from 'navigation/wrapper/screen/DefaultScreenWrapper';
import { LogoAndReturnOnlyScreenWrapper } from 'navigation/wrapper/screen/LogoAndReturnOnlyScreenWrapper';
import { LogoOnlyScreenWrapper } from 'navigation/wrapper/screen/LogoOnlyScreenWrapper';
import { NoFooterScreenWrapper } from 'navigation/wrapper/screen/NoFooterScreenWrapper';
import { ScreenWrapper } from 'navigation/wrapper/screen/ScreenWrapper';
import { PrivateRouteWrapper } from 'navigation/wrapper/security/PrivateRouteWrapper';

const screenConfig: RouteObject[] = [
  {
    element: <DefaultScreenWrapper />,
    children: [
      { path: '/', element: <Home /> },
      {
        path: '/campaigns',
        children: [
          { path: '', element: <Campaigns /> },
          { path: ':campaignName/:campaignId', element: <CampaignPreview /> },
          { path: ':campaignId', element: <CampaignPreview /> },
        ],
      },
      {
        path: '/items',
        children: [
          { path: '', element: <HotDeals /> },
          { path: ':itemName/:itemId', element: <ItemPreview /> },
          { path: ':itemId', element: <ItemPreview /> },
        ],
      },
      { path: '/messages/from/:senderId', element: <MessagePreview /> },
      { path: '/give-phone', element: <GivePhone /> },
      { path: '/payment-declined', element: <PaymentDeclined /> },
      { path: '/thanks', element: <Thanks /> },
      { path: '/reset-password', element: <ResetPassword /> },
      { path: '/reset-password-success', element: <ResetPasswordSuccess /> },
      { path: '/reset-password-error', element: <ResetPasswordError /> },
      { path: '/activate-account-success', element: <ActivateAccountSuccess /> },
      { path: '/activate-account-error', element: <ActivateAccountError /> },
      { path: '/activate-account', element: <ActivateAccount /> },
      { path: '/download', element: <Download /> },
      { path: '/unsubscribe', element: <UnsubscribeEmail /> },
      {
        path: '/my-profile',
        element: <PrivateRouteWrapper />,
        children: [
          {
            element: <MyProfileWrapper />,
            children: [
              { path: '', element: <MyProfile /> },
              { path: 'my-activity', element: <MyActivityPage /> },
              { path: 'my-activity/campaigns', element: <MyActivityCampaignsPage /> },
              { path: 'my-activity/selling', element: <MyActivitySellingPage /> },
              { path: 'my-activity/donated', element: <MyActivityDonatedPage /> },
              { path: 'my-activity/bought', element: <MyActivityBoughtPage /> },
              { path: 'shipping-address', element: <ShippingAddressPage /> },
              { path: 'payment', element: <PaymentPage /> },
            ],
          },
          { path: 'my-activity/donated/:itemId/', element: <MyActivityTrackedItemPage /> },
          { path: 'my-activity/bought/:itemId/:orderId', element: <MyActivityTrackedItemPage /> },
        ],
      },
    ],
  },
  {
    path: '/checkout',
    element: <PrivateRouteWrapper />,
    children: [
      {
        element: <LogoOnlyScreenWrapper />,
        children: [{ path: ':itemId', element: <ItemCheckout /> }],
      },
      {
        element: <LogoAndReturnOnlyScreenWrapper />,
        children: [{ path: 'success', element: <CheckoutSuccess /> }],
      },
    ],
  },
  {
    path: '/messenger',
    element: <NoFooterScreenWrapper />,
    children: [
      {
        element: <PrivateRouteWrapper />,
        children: [
          { path: '', element: <Messenger /> },
          { path: ':conversationId', element: <Messenger /> },
          { path: 'context/:context', element: <Messenger /> },
        ],
      },
    ],
  },
  {
    element: <DefaultScreenWrapper />,
    children: [{ path: '*', element: <PageNotFound /> }],
  },
];

export const routesConfig: RouteObject[] = [
  {
    element: <RootWrapper />,
    children: [{ element: <ScreenWrapper />, children: screenConfig }],
  },
];
