import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ButtonBase from 'components/buttons/ButtonBase';
import { DeliveryType } from 'features/checkout/delivery/deliveryType';
import { useHotDealsFilters } from 'providers/HotDealsFiltersProviders';

const Container = styled.div`
  margin-right: auto;
  display: flex;
  gap: 24px;

  ${theme.mq.phone} {
    gap: 0;
  }
`;

const Button = styled(ButtonBase)<{ isActive: boolean }>`
  white-space: nowrap;
  background-color: ${theme.color.white};
  color: ${props => (props.isActive ? theme.color.red : theme.color.black)};
`;

export const DeliverySection = () => {
  const { t } = useTranslation('item');
  const { filters, updateFilters } = useHotDealsFilters();

  const deliveryOptions = [
    { id: 1, label: t('delivery.all'), type: 'All' },
    { id: 2, label: t('delivery.shippable'), type: 'Shipment' },
    { id: 3, label: t('delivery.pickup'), type: 'PickUp' },
  ];

  const handleButtonClick = (type: DeliveryType) => {
    updateFilters({ deliveryType: type });
  };

  return (
    <Container>
      {deliveryOptions.map(option => (
        <Button
          key={option.id}
          isActive={filters.deliveryType === option.type}
          onClick={() => handleButtonClick(option.type as DeliveryType)}>
          {option.label}
        </Button>
      ))}
    </Container>
  );
};
